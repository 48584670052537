<template>
  <span>
      <h1 class="headline blue--text text-center mt-4 mb-3">SMS-viestien seuranta</h1>

      <v-divider></v-divider>
      Valitse päivämäärä:
      <v-row>
          <v-col cols="2">
            <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
          </v-col>
          <v-col cols="2">
              <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
          </v-col>
          <v-col cols="3">
              <v-text-field v-model="keyword" placeholder="seksi" label="avainsana"></v-text-field>
          </v-col>
          <v-col cols="3">
              <v-text-field v-model="msisdn" placeholder="31.12.23" label="asiakkaan puh. numero"></v-text-field>
          </v-col>
          <v-col>
              <v-checkbox
                v-model="exclude_direct_marketing"
                label="suoramarkkinointi pois"
              ></v-checkbox>
          </v-col>
          <v-col
              cols="10">
              <v-text-field v-model="content" placeholder="viesti" label="viestin sisältö"></v-text-field>
          </v-col>
          <v-col cols="2">
              <v-btn
                  class="v-btn ma-3 green float-right"
                  @click="getResources"
                  small
              >
                  HAE
              </v-btn>
          </v-col>
      </v-row>

      <v-data-table
              class="mt-4"
              :headers="headers"
              :items="resources"
              :loading="apiLoading"
              loading-text="Ladataan SMS-viestejä..."
              no-data-text="Ei tietoja. Syötä hakuehdot ja paina HAE"
      >
          <template v-slot:item.application="{ item }">
              {{ item.application.name }}
          </template>
          <template v-slot:item.connection="{ item }">
              <template v-if="item.connection">
                {{ item.connection.operator }}
              </template>
          </template>
          <template v-slot:item.received_message="{ item }">
              <template v-if="item.received_message && item.received_message[0]">
                  {{ item.received_message[0].message }}
              </template>
          </template>
          <template v-slot:item.sent_message="{ item }">
              <template v-if="item.sent_message && item.sent_message[0]">
                {{ item.sent_message[0].description }}
              </template>
              <template v-else-if="item.sent_payload">
                  {{ item.sent_payload }}
              </template>
          </template>
          <template v-slot:item.short_code="{ item }">
              <template v-if="item.connection">
                {{ item.connection.short_code }}
              </template>
          </template>
          <template v-slot:item.price="{ item }">
              {{ item.credio_price ? item.operator_price / 100 : item.operator_price / 100 }}€
          </template>
      </v-data-table>

      <v-dialog
              v-model="dialog"
              max-width="1200"
      >
          <v-card>
              <v-card-text>{{ selection }}
              </v-card-text>
          </v-card>
      </v-dialog>


  </span>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "SmsMonitoring",
    data() {
        return {
            dialog: false,
            selection: null,
            headers: [
                {text: 'Numero', value: 'consumer_msisdn'},
                {text: 'Avainsana(t)', value: 'keyword1'},
                {text: 'Vastaanotettu', value: 'received_date'},
                {text: 'Viesti', value: 'received_message'},
                {text: 'Vastattu', value: 'sent_date'},
                {text: 'Vastaus', value: 'sent_message'},
                {text: 'Sovellus', value: 'application'},
                {text: 'Operator', value: 'connection'},
                {text: 'Yhteys', value: 'short_code'},
                {text: 'Hinta', value: 'price'},
            ],
            resources: [],
            apiLoading: false,
            periods: [],
            selectedResource: null,
            copied: false,
            startDate: null,
            endDate: null,
            msisdn: null,
            keyword: null,
            content: null,
            exclude_direct_marketing: 1,
        };
    },
    methods: {

        async getResources(){
            this.apiLoading = true
            this.copied = false
            this.salaries = []
            await this.axios.get('reports/sms_detail_report', {
                params:{
                    from: this.startDate,
                    to: this.endDate,
                    msisdn: this.msisdn,
                    keyword: this.keyword,
                    content: this.content,
                    exclude_direct_marketing: this.exclude_direct_marketing,
                }
            }).then((resources) => {
                this.resources = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.apiLoading = false
        },


    },
    mounted(){
        this.startDate = moment().startOf('month').format('DD.MM.YY');
        this.endDate = moment().format('DD.MM.YY');
        //this.getResources()
    }
}
</script>

<style scoped>

</style>